<template>
  <div class="wameed-dashboard-page-content">
    <page-header
        :title="isUpdate?$t('products.update_btn'):$t('products.new_btn')"
        :paths="[{title:$t('products.title'),link:'products'}]"
    />
    <section class="wameed-dashboard-page-content_body products_create">
      <b-row class="px-2">
        <b-col lg="12" md="12" class="px-0">
          <b-card no-body class="wameed-card">
            <b-card-header class="align-items-start tab-title">
              <h5 class="text-med-20 text-font-main mb-0">
                {{ $t('products.info') }}
              </h5>
            </b-card-header>
            <wameed-form @onSubmit="submit">
              <div slot-scope="{ invalid }">
                <b-card-body
                    class="position-relative justify-content-between px-5"
                >
                  <!-- form -->
                  <div class="d-flex flex-wrap">
                    <b-col cols="12" md="4">
                      <text-input
                          id="name"
                          v-model="form.name"
                          :label="$t('products.form.name')"
                          rules="required"
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <wameed-dropdown
                          v-model="form.category"
                          :label="$t('products.form.category')"
                          :placeholder="$t('form.category.placeholder')"
                          variant="disable"
                          :items="getCategories"
                          class="wameed_dropdown"
                          title="name"
                          rules="required"
                          :no_options="$t('common.no_options')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <text-input
                          id="name"
                          v-model="form.link"
                          :label="$t('products.form.link')"
                          rules="url"
                          :placeholder="$t('form.text.placeholder')"
                      />
                    </b-col>
                    <b-col cols="12" md="4">
                      <TextInput
                          v-model="form.price"
                          rules="required"
                          field-classes="w-100"
                          :label="$t('products.form.price')"
                          :placeholder="$t('form.text.placeholder')"
                          is-append
                          type="number"
                          :append-text="$t('common.rial')"
                      />
                    </b-col>

                    <b-col cols="12" md="4">
                      <TextInput
                          v-model="form.dis_price"
                          field-classes="w-100"
                          :rules="'max_value:'+form.price"
                          :label="$t('products.form.dis_price')"
                          :placeholder="$t('form.text.placeholder')"
                          is-append
                          type="number"
                          :append-text="$t('common.rial')"
                      />
                    </b-col>


                    <b-col cols="12" md="4">
                      <wameed-image-cropper-input
                          :label="$t('products.form.image')"
                          :placeholder="$t('form.image.placeholder')"
                          :uploaded-image="form.image"
                          @changed="setImage"
                          @unsetImage="unsetImage"
                          :show-delete="true"
                          :delete-icon="'trash-icon'"
                          :width="200"
                          :height="200"
                      />
                    </b-col>
                    <b-col cols="12" md="12">
                      <wameed-text-area
                          v-model="form.description"
                          :label="$t('products.form.desc')"
                          :placeholder="$t('form.text.placeholder')"
                          rows="5"
                      />
                    </b-col>

                  </div>

                  <div class="">
                    <p class="text-med-18 text-font-main">
                      {{ $t('products.form.size') }}
                    </p>
                    <hr>
                    <additional-data type="size" ref="sizes" :btn-text="$t('products.btn.size')"
                                     :values="detail.sizes"/>

                  </div>

                  <div class="">
                    <p class="text-med-18 text-font-main">
                      {{ $t('products.form.types') }}
                      <span class="text-reg-14 text-font-secondary">{{ $t('products.one_selection') }}</span>
                    </p>
                    <hr>

                    <additional-data type="type" ref="types" :btn-text="$t('products.btn.type')"
                                     :values="detail.types"/>

                  </div>

                  <div class="">
                    <p class="text-med-18 text-font-main">
                      {{ $t('products.form.addons') }}
                      <span class="text-reg-14 text-font-secondary">{{ $t('products.multi_selection') }}</span>
                    </p>
                    <hr>
                    <additional-data type="addon" ref="addons" :btn-text="$t('products.btn.addon')"
                                     :values="detail.addons"/>

                  </div>


                </b-card-body>
                <b-card-footer class="px-4 py-3">
                  <div class="d-flex flex-wrap justify-content-between">
                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-white rounded-10"
                          :title="isUpdate?$t('products.update_btn'):$t('products.new_btn')"
                          :disabled="invalid"
                          type="submit"
                          variant="main"
                      />
                    </div>

                    <div class="px-4 py-3">
                      <wameed-btn
                          classes="  text-med-14 text-font-secondary rounded-10 "
                          :title="$t('common.cancel')"
                          type="button"
                          variant="gray"
                          @onClick="goBack"
                      />
                    </div>
                  </div>
                </b-card-footer>
              </div>
            </wameed-form>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex';

import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import {
  WameedBtn,
  WameedDropdown,
  WameedForm,
  WameedTextArea,
  WameedTextInput as TextInput
} from 'wameed-ui/dist/wameed-ui.esm'
import AdditionalData from "@/views/pages/products/components/additionalData";
import WameedImageCropperInput from "@/components/wameed/WameedImageCropperInput.vue";

export default {
  components: {
    WameedImageCropperInput,
    AdditionalData,
    TextInput,
    WameedTextArea,
    WameedBtn,
    WameedDropdown,
    WameedForm,
    PageHeader,
  },
  data() {
    return {
      form: {
        name: '',
        price: '',
        link: '',
        dis_price: '',
        image: '',
        category: '',
        category_id: '',
        description: '',
        sizes: [],
        types: [],
        addons: [],
      },


    };
  },
  computed: {
    ...mapGetters({
      getCategories: 'vendor/products/getCategories',
      detail: 'vendor/products/getDetail',
    }),
    isUpdate() {
      return (this.$route.name === 'products-update' && this.$route.params.id);
    }
  },
  beforeCreate() {
    if (this.$route.name === 'products-update' && this.$route.params.id) {
      this.$store.dispatch("vendor/products/detail", this.$route.params.id).then(() => {
        this.form = this.detail;
        this.form.category = this.getCategories.filter((cat) => cat.id === this.form.category_id)[0];
      });
    } else {
      this.$store.dispatch("vendor/products/unsetDetail");
    }
  },
  created() {
    this.$store.dispatch('vendor/products/getCategories');

  },
  methods: {
    ...mapActions({
      addCoupon: 'addCoupon',
    }),

    setImage(file) {
      console.log('set image')
      console.log(file)
      console.log('set image')
      this.form.image = file;
    },
    unsetImage() {
      this.form.image = null;
    },



    goBack() {
      this.$router.push({name: 'products'});
    },

    submit() {
      this.form.sizes = Object.values(this.$refs.sizes.items);
      this.form.types = Object.values(this.$refs.types.items);
      this.form.addons = Object.values(this.$refs.addons.items);
      this.form.category_id = this.form.category.id;

      if (this.form.image && this.form.image.url) {
        this.form.image = this.form.image.url;
      }

      if (this.$route.name === 'products-update' && this.$route.params.id) {
        this.form.id = this.$route.params.id;
        this.$store.dispatch('vendor/products/update', this.form);
      } else {
        this.$store.dispatch('vendor/products/create', this.form);
      }

    },
  }
  ,
}
;
</script>
