<template>
  <b-modal
      v-model="show"
      content-class="package-modal rounded-8"
      dialog-class="wameed-modal-md"
      no-fade
  >
    <template slot="modal-header-close">
      <close-icon/>
    </template>
    <template slot="modal-title">
      <div class="d-block text-center">
        <h3 class="text-reg-18">
          {{ $t("common.edit_image") }}
        </h3>
      </div>
    </template>


    <div id="result"></div>
    <div class="modal_container">
      <div>
        <h3 class="preview_title">{{ $t("common.preview") }}</h3>
        <div class="preview_container">
          <div id="preview" ref="previewRef" class="preview" dir="ltr">
            <img :src="modelSrc"
                 style="object-fit: contain; width: 100%; height: 100%;" alt=""/>
          </div>
        </div>
        <div class="preview_sizes">
          <span class="preview_sizes-ratio"> {{ getRatio() }} </span>
          -
          <span class="preview_sizes-width"> {{ parseInt(this.previews.w) }}px * {{
              parseInt(this.previews.h)
            }}px </span>
        </div>
      </div>

      <div class="image_cropper_container" dir="rtl">
        <vue-cropper @realTime="realTime" class="image_cropper" ref="cropper" v-bind="option"
                     :fixedNumber="[width, height]"
        />
      </div>
    </div>


    <template slot="modal-footer">
      <wameed-btn
          classes="  text-med-14 text-white mx-0 rounded-8"
          :title="$t('btn.add')"
          type="submit"
          variant="main"
          @onClick="cropImage"
      />
      <wameed-btn
          classes="  text-med-14 text-font-secondary mx-0 rounded-8 "
          :title="$t('btn.cancel')"
          type="button"
          variant="gray"
          @onClick="closeModal"
      />
    </template>


  </b-modal>
</template>

<script>
import {WameedBtn,} from 'wameed-ui/dist/wameed-ui.esm'

export default {
  components: {
    WameedBtn,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    fixed: {
      type: Boolean,
      default: true
    },
    src: {
      type: String,
      default: ""
    },
    width: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 1
    },
    options: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      previews: {},
      modelSrc: '',
      imageRef: null,
      previewRef: null,
      cropper: null,
      croppedImageWidth: "0px",
      croppedImageHeight: "0px",
      option: {
        img: this.src,
        // size: 1,
        // full: false,
        outputType: "png",
        canMove: true,
        fixedBox: false,
        original: false,
        canMoveBox: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        centerBox: true,
        // high: false,
        // ratio: 1,
        // cropData: {},
        enlarge: 1,
        maxImgSize: 3000,
        fixed: this.fixed,
        fillColor: '',
      },
    };
  },
  computed: {
    ratio() {
      return this.width / this.height;
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {

        if (!value) {
          this.$emit('close');
        }
      },
    },
  }, watch: {
    show(val) {
      // if (this.src)
      //   this.option.img = this.src;
      if (!val) {
        this.$emit("close");
      }
    }
  },

  methods: {
    cropImage() {
      this.$refs.cropper.getCropData((data) => {
        this.modelSrc = data
      })
      this.$emit("onCrop", this.modelSrc);

    },
    getRatio() {
      const min = Math.min(this.width, this.height);
      let w = (this.width / min).toFixed(2);
      let h = (this.height / min).toFixed(2);
      w = w.replace(".00", "");
      h = h.replace(".00", "");
      return `${w}:${h}`;
    },

    realTime(data) {
      this.previews = data

      this.$refs.cropper.getCropData((data) => {
        this.modelSrc = data
      })
    },
    closeModal() {
      this.show = false;
    },
  },
};
</script>

<style scoped>

img {
  max-width: 100%;
}

.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}

/* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
.cropper-view-box {
  outline: 0;
  box-shadow: 0 0 0 1px #39f;
}

.row,
.preview {
  overflow: hidden;
  border-radius: 8px;
  background: #d9d9d9;
  width: 120px;
  height: 120px;
  flex-shrink: 0;
}

.image_cropper {
  width: 437px;
  height: 328px;
  flex-shrink: 0;
  border-radius: 16px;
}


.preview_container {
  border-radius: 8px;
  border: 1px solid var(--grey-tulle-grey-200, #e8e9ea);
  background: var(--grey-tulle-grey-50, #f9f9fa);
  display: inline-flex;
  padding: 12px;
  align-items: flex-end;
  gap: 8px;
}

.image_cropper_container {
  border-radius: 16px;
  border: 1px solid var(--grey-tulle-grey-200, #e8e9ea);
  background: var(--grey-tulle-grey-50, #f9f9fa);
  display: inline-flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
}

.preview_title {
  color: var(--grey-tulle-grey-1000-black, #1c1d1e);
  text-align: left;

  /* 16px/Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.modal_title {
  color: var(--grey-tulle-grey-900, #383a3d);
  text-align: left;

  /* 24px/Medium */
  font-family: IBM Plex Sans Arabic;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 150% */
}

.modal_container {
  display: flex;
  direction: rtl;
  align-items: start;
  justify-content: end;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;
}

.preview_sizes {
  text-align: center;
  /* 14px/Medium */
  font-family: IBM Plex Sans Arabic;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-top: 8px;
}

.preview_sizes-ratio {
  color: var(--brand-genestealer-purple-600, #8363aa);
}

.preview_sizes-width {
  color: var(--grey-tulle-grey-1000-black, #1c1d1e);
}
</style>