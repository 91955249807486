<template>
  <!-- SECOND REPEATER - START -->
  <b-row class="d-inline additional_data">
    <b-col md="6" class="">
      <WameedBtn
          variant="light-light"
          append-icon="add-filled-circle-icon"
          classes="text-font-secondary rounded-12 border-font-secondary2 medium-btn mb-5"
          :title="btnText"
          @onClick="addSize"
      />
    </b-col>

    <div
        v-for="(item, index) in items"
        :id="item.id"
        :key="item.id"
        class="mb-2 additional_form"
    >
      <b-col class="d-flex align-item-center px-0 flex-wrap">
        <b-col md="6">
          <WameedTextInput
              :id="type + index"
              v-model="item.name"
              rules="required"
              field-classes="w-100"
              :label="$t('products.form.'+type)"
              :placeholder="$t('form.text.placeholder')"
          />
        </b-col>
        <b-col md="6">
          <WameedTextInput
              :id="type+'-price' + index"
              v-model="item.price"
              rules="required"
              :label="$t('form.price.label')"
              :placeholder="$t('form.text.placeholder')"
              is-append
              :append-text="$t('common.rial')"
              type="number"
              field-classes="w-100"
          />
        </b-col>
      </b-col>

      <div>
        <button class="remove-icon" @click="removeSize(index)">
          <trash-icon/>
          {{ $t('btn.delete') }}
        </button>
      </div>

    </div>

  </b-row>
  <!-- SECOND REPEATER - END -->
</template>

<script>
import {WameedBtn, WameedTextInput} from 'wameed-ui/dist/wameed-ui.esm';

export default {
  name: "additionalData",
  components: {
    WameedTextInput, WameedBtn
  },
  props: {
    type: String,
    btnText: String,
    values: Array,
  },
  data() {
    return {
      items: [],
      counter: 0,
    }
  },
  methods: {

    addSize() {
      this.items.push({
        id: (this.counter),
      });
      this.counter -= 1;
    },

    removeSize(index) {
      this.items.splice(index, 1);
    },


  },
  watch: {
    values(values) {
      this.items=values;
    }
  },

}
</script>

